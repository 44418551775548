import { render, staticRenderFns } from "./ScenkonstSubscriptionsList.vue?vue&type=template&id=0019bc15&"
import script from "./ScenkonstSubscriptionsList.vue?vue&type=script&lang=ts&"
export * from "./ScenkonstSubscriptionsList.vue?vue&type=script&lang=ts&"
import style0 from "./ScenkonstSubscriptionsList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardSmaller: require('/tmp/build_e115b7cb/src/components/card/Smaller.vue').default,HorizontalScrollSlider: require('/tmp/build_e115b7cb/src/components/layout/HorizontalScrollSlider.vue').default,Radio: require('/tmp/build_e115b7cb/src/components/forms/Radio.vue').default,BlockSubscriptionListDetails: require('/tmp/build_e115b7cb/src/components/block/SubscriptionListDetails.vue').default,Disclosure: require('/tmp/build_e115b7cb/src/components/misc/Disclosure.vue').default,BlockWrapper: require('/tmp/build_e115b7cb/src/components/block/BlockWrapper.vue').default})
